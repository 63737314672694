import React from 'react';
import "../scss/main.scss"
import Header from '../components/header';
import Main from '../components/main';
import PageHeader from '../components/Organism/PageHeader/PageHeader';
import { Helmet } from 'react-helmet';
import StartWork from '../components/forms/StartWork';
import Footer from '../components/Footer/Footer';
import OurServicesLite from '../components/OurServices/OurServicesLite';


function ContextAdvertisingPage(props) {
    const form = <StartWork/>;
    return (
        <>
        
            <Helmet>
                <title>Настройка контекстной рекламы Яндекс Директ и Google Adwords</title>
                <meta name="description" content="Создания прибыльной рекламы выходящие за грани стандартной настройки" />
            </Helmet>
            <Header/>
            <Main>
                <PageHeader
                    h1={"Настройка контекстной рекламы Яндекс Директ и Google Adwords"}
                    p={"Создание прибыльной рекламы выходящие за грани стандартной настройки"}
                    col={3}
                    сards={[
                        {
                            name:"от 2 до 30 заявок в день",
                            desk:"Получайте клиентов с первого дня запуска контекстной рекламы"
                        },
                        {
                            name:"Заявки от 90 рублей",
                            desk:"Таких результатов мы добились на нескольких наших проектах"
                        },
                        {
                            name:"Бесплатный аудит",
                            desk:"Сделаем бесплатно аудит ваших рекламных кампаний"
                        },
                    ]}
                    modalButton modalContent={form}/>

                <OurServicesLite col={2} cardsData={[
                        {
                          header:"Ключевые слова",
                          desc:"Для вашего бизнеса подбираются все возможные ключевые запросы и сортируются по степени заинтересованности клиента к покупке",
                        },
                        {
                            header:"Объявления",
                            desc:"Под каждый высоко частотный и средне частотный запрос, составляются релевантные объявления. Также в объявлениях учитываются потребности вашей целевой аудитории.",
                        },
                        {
                            header:"Аналитика",
                            desc:"На ваш сайт устанавливаются и настраиваются системы аналитики, для дальнейшей оптимизации рекламы и обеспечения её эффективности",
                        },
                        {
                            header:"A/B тесты",
                            desc:"Составление дополнительных объявлений для каждого запроса, для увеличения максимального попадания в нужды ваших клиентов и увеличения продаж.",
                        },
                        {
                            header:"Ретаргетинг",
                            desc:"Возвращаем посетителей перешедших по рекламе но не совершивших ключевого действия, для увеличения конверсии рекламных компаний.",
                        },
                        {
                            header:"Контроль",
                            desc:"Отслеживаем наиболее эффективные позиции объявлений, для увеличения максимального количества заявок по минимальной цене.",
                        },
                
                    ]} desc="Опираясь на ваши задачи, проводим настройку рекламных компаний.">Как мы работаем с контекстной рекламой</OurServicesLite>

            </Main>
            <Footer/>
        </>
    );
}

export default ContextAdvertisingPage;